import { Grid, Typography } from '@mui/material';
import { find, map, split, trim } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, ContentBox, Dropdown, Input } from '../../components';
import { ReadCloudParkTicketResponse } from '../../services/PrivateTicketPurchase';
import {
  fetchReadCloudParkTicket,
  privateTicketPurchaseSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { BuyPrivateTicketModal } from './modals/BuyPrivateTicketModal';
import { EditCPFModal } from './modals/EditCPFModal';

export const PrivateTicketPurchase: React.FC = () => {
  const dispatch = useDispatch();
  // const ref = useRef<TextFieldProps>(null);
  const { driverId, cpf } = useSelector((state) => state.profileReducer);
  const { vehicles } = useSelector((state) => state.vehiclesReducer);
  const { paymentMethods } = useSelector(
    (state) => state.paymentMethodsReducer,
  );
  const {
    privateTicketData,
    cloudParkTicketLoading,
    privateTicketPurchaseLoading,
    editCPFIsOpen,
    editCPFLoading,
  } = useSelector((state) => state.privateTicketPurchaseReducer);
  const { pixLoading } = useSelector((state) => state.pixReducer);
  const [paymentFormEnabled, setPaymentFormEnabled] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const [selectedPaymentMethodToShow, setSelectedPaymentMethodToShow] =
    useState<string>('');
  const [entryTime, setEntryTime] = useState<string>('');
  const [ticketId, setTicketId] = useState<string>('');
  const [ticketValue, setTicketValue] = useState<string | null>(null);

  useEffect(() => {
    if (privateTicketData) {
      const vehicle = find(vehicles, [
        'plate',
        // trim(split(e.toString(), '(')[0]),
        privateTicketData.cloudParkPlate,
      ]);
      if (vehicle) {
        setSelectedVehicle(vehicle);
        privateTicketPurchaseSlice.actions.setPrivateTicketData({
          ...privateTicketData,
          vehicleId: vehicle.id.toString(),
        });
      }
      setCode(privateTicketData.cloudParkTicketId);
      setTicketValue(privateTicketData.cloudParkPrice);
      setEntryTime(
        moment(privateTicketData?.cloudParkEntryDate).format(
          'DD/MM/YYYY HH:mm:ss',
        ) || '',
      );
    }
  }, [privateTicketData]);

  const handleReadTicket = () => {
    dispatch(
      fetchReadCloudParkTicket({
        driverId: driverId.toString(),
        cloudParkTicketId: ticketId,
      }),
    ).then((res: { payload: ReadCloudParkTicketResponse }) => {
      if (res.payload) {
        setPaymentFormEnabled(true);
      } else {
        setPaymentFormEnabled(false);
      }
    });
  };

  const handleSelectedPaymentMethod = (e: string) => {
    if (e !== 'PIX') {
      const paymentMethod = find(paymentMethods, [
        'number',
        trim(split(e.toString(), ' ')[1]),
      ]);
      setSelectedPaymentMethod(paymentMethod);
    } else {
      setSelectedPaymentMethod({
        id: 0,
        number: 0,
        brand: '',
        bankId: null,
        default: 0,
      });
    }
    setSelectedPaymentMethodToShow(e.toString());
  };

  const handleBuyTicket = () => {
    if (selectedPaymentMethod && privateTicketData) {
      if (selectedPaymentMethod.id === 0 && !cpf) {
        dispatch(
          privateTicketPurchaseSlice.actions.toggleEditCPFPrivateTicketIsOpen(),
        );
      } else {
        dispatch(
          privateTicketPurchaseSlice.actions.setPrivateTicketData({
            ...privateTicketData,
            paymentMethodId: selectedPaymentMethod.id.toString(),
          }),
        );
        dispatch(
          privateTicketPurchaseSlice.actions.toggleBuyPrivateTicketIsOpen(),
        );
      }
    }
  };

  const handleResetTicket = () => {
    dispatch(privateTicketPurchaseSlice.actions.resetPrivateTicketData());
    setCode('');
    setTicketValue(null);
    setSelectedVehicle(null);
    setSelectedPaymentMethod(null);
    setEntryTime('');
    setTicketId('');
    setPaymentFormEnabled(false);
    setSelectedPaymentMethodToShow('');
  };

  return (
    <ContentBox loading={privateTicketPurchaseLoading || pixLoading}>
      <Grid
        container
        sx={{
          marginTop: {
            xs: '20px',
            sm: 0,
          },
        }}
      >
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 1,
              textAlign: 'center',
            }}
            color={palette.text.main}
          >
            {paymentFormEnabled
              ? 'Preencha os campos para realizar o pagamento'
              : 'Insira abaixo o código do ticket ou placa do veículo para buscar suas informações'}
          </Typography>
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Input
            data-testid='message'
            type='text'
            margin='normal'
            label='Código ou placa'
            rows={6}
            value={ticketId}
            variant='standard'
            onChange={(value) => {
              setTicketId(value);
            }}
            sx={{
              '& .MuiInputBase-input': {
                textTransform: 'uppercase',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          marginTop: {
            xs: '20px',
            sm: 0,
          },
        }}
      >
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Input
            margin='normal'
            label='Código'
            type='text'
            value={code}
            disabled
          />
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dropdown
            options={map(vehicles, (item) => {
              return `${item.plate} (${item.type})`;
            })}
            data-testid='vehicle'
            margin='normal'
            label='Veículo'
            type='text'
            value={selectedVehicle ? selectedVehicle.plate : ''}
            disabled
          />
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Input
            margin='normal'
            label='Horário de entrada'
            type='text'
            value={entryTime}
            disabled
          />
        </Grid>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dropdown
            options={[
              'PIX',
              ...map(paymentMethods, (item) => {
                return `**** ${item.number} (${item.brand})`;
              }),
            ]}
            data-testid='paymentMethod'
            margin='normal'
            label='Meio de pagamento'
            type='text'
            value={selectedPaymentMethodToShow}
            onChange={handleSelectedPaymentMethod}
            disabled={!paymentFormEnabled}
          />
        </Grid>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: {
              sm: 0,
              xs: 3,
            },
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 1,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
            color={palette.primary.main}
          >
            Valor:&nbsp;
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 1,
              textTransform: 'uppercase',
            }}
            color={palette.primary.main}
          >
            {ticketValue
              ? ticketValue === '0,00'
                ? 'Grátis'
                : `R$${ticketValue}`
              : `R$ -`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          margin: 'auto',
          paddingTop: '20px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          variant='contained'
          label={paymentFormEnabled ? 'Realizar pagamento' : 'Buscar ticket'}
          type='submit'
          disabled={paymentFormEnabled ? !selectedPaymentMethod : !ticketId}
          onClick={paymentFormEnabled ? handleBuyTicket : handleReadTicket}
          color='secondary'
          loading={
            cloudParkTicketLoading || privateTicketPurchaseLoading || pixLoading
          }
          style={{ width: '100%', marginTop: 10 }}
        />
        <Button
          label='Limpar'
          type='submit'
          onClick={() => {
            handleResetTicket();
          }}
          color='primary'
          style={{ width: '100%', marginTop: 10 }}
        />
      </Grid>
      <BuyPrivateTicketModal
        handleResetTicket={handleResetTicket}
        code={code}
        selectedVehicle={selectedVehicle}
        selectedPaymentMethod={selectedPaymentMethod}
        entryTime={entryTime}
      />
      <EditCPFModal />
    </ContentBox>
  );
};
