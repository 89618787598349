import {
  Construction,
  CreditCard,
  Error,
  History,
  Logout,
  Menu,
  ShoppingCart,
  TimeToLeave,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Toolbar,
  CssBaseline,
  AppBar,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Container,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { map } from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import projectPackage from '../../../../package.json';
import { ReactComponent as Private } from '../../../assets/images/private_parking_icon.svg';
import {
  DASHBOARD,
  HISTORY,
  IRREGULARITIES,
  PAYMENT_METHODS,
  PIX,
  PROFILE,
  SUPPORT,
  TICKET_PURCHASE,
  PRIVATE_TICKET_PURCHASE,
  VEHICLES,
} from '../../../routes';
import { Store, authSlice } from '../../../stores';
import { logo, palette } from '../../../theme';
import { Avatar } from '../../Avatar';
import { StyledLogo } from '../styles';

const pages = [
  'Comprar ticket',
  'Meus veículos',
  'Meios de pagamento',
  'Histórico de uso',
  'Irregularidades',
  'Privativo',
  'Suporte',
  'Sair',
];

interface Props {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  window?: () => Window;
  drawerWidth: number;
}

export const FrameBars = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { children } = props;
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleButtonClick = (index: number) => {
    {
      switch (index) {
        case 0:
          navigate(TICKET_PURCHASE);
          break;
        case 1:
          navigate(VEHICLES);
          break;
        case 2:
          navigate(PAYMENT_METHODS);
          break;
        case 3:
          navigate(HISTORY);
          break;
        case 4:
          navigate(IRREGULARITIES);
          break;
        case 5:
          navigate(PRIVATE_TICKET_PURCHASE);
          break;
        case 6:
          navigate(SUPPORT);
          break;
        default:
          Store.dispatch(authSlice.actions.logout());
          window.location.reload();
          break;
      }

      setMobileOpen(!mobileOpen);
    }
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case DASHBOARD:
        return 'Painel';
      case HISTORY:
        return 'Histórico de uso';
      case IRREGULARITIES:
        return 'Irregularidades';
      case PAYMENT_METHODS:
        return 'Meios de pagamento';
      case PIX:
        return 'Pix';
      case PROFILE:
        return 'Editar perfil';
      case SUPPORT:
        return 'Suporte';
      case TICKET_PURCHASE:
        return 'Comprar ticket';
      case PRIVATE_TICKET_PURCHASE:
        return 'Privativo';
      case VEHICLES:
        return 'Meus veículos';
      default:
        return '';
    }
  };

  const drawer = (
    <div>
      <List sx={{ paddingTop: 0, backgroundColor: palette.grey[100] }}>
        <ListItem
          key='logo'
          disablePadding
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <ListItemButton
            onClick={() => {
              navigate(DASHBOARD);
              setMobileOpen(!mobileOpen);
            }}
            sx={{
              height: { md: '64px', xs: '56px' },
              display: 'flex',
              justifyContent: 'center',
              align: 'center',
              backgroundColor: 'white',
            }}
          >
            <StyledLogo
              src={logo}
              alt='logo'
              loading='lazy'
              sx={{ display: 'flex', mr: 1 }}
            />
          </ListItemButton>
        </ListItem>
        {map(pages, (text, index) => (
          <>
            <Divider />
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => handleButtonClick(index)}
                sx={{ paddingTop: 2, paddingBottom: 2 }}
              >
                <ListItemIcon sx={{ color: palette.primary.main }}>
                  {index === 0 ? (
                    <ShoppingCart
                      color={
                        location.pathname === TICKET_PURCHASE
                          ? 'secondary'
                          : 'primary'
                      }
                    />
                  ) : index === 1 ? (
                    <TimeToLeave
                      color={
                        location.pathname === VEHICLES ? 'secondary' : 'primary'
                      }
                    />
                  ) : index === 2 ? (
                    <CreditCard
                      color={
                        location.pathname === PAYMENT_METHODS
                          ? 'secondary'
                          : 'primary'
                      }
                    />
                  ) : index === 3 ? (
                    <History
                      color={
                        location.pathname === HISTORY ? 'secondary' : 'primary'
                      }
                    />
                  ) : index === 4 ? (
                    <Error
                      color={
                        location.pathname === IRREGULARITIES
                          ? 'secondary'
                          : 'primary'
                      }
                    />
                  ) : index === 5 ? (
                    <Private
                      style={{
                        fill:
                          location.pathname === PRIVATE_TICKET_PURCHASE
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                        color:
                          location.pathname === SUPPORT
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                        width: '23px',
                        height: '23px',
                      }}
                    />
                  ) : index === 6 ? (
                    <Construction
                      color={
                        location.pathname === SUPPORT ? 'secondary' : 'primary'
                      }
                    />
                  ) : (
                    <Logout />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={{
                    color:
                      index === 0
                        ? location.pathname === TICKET_PURCHASE
                          ? palette.secondary.main
                          : palette.primary.main
                        : index === 1
                        ? location.pathname === VEHICLES
                          ? palette.secondary.main
                          : palette.primary.main
                        : index === 2
                        ? location.pathname === PAYMENT_METHODS
                          ? palette.secondary.main
                          : palette.primary.main
                        : index === 3
                        ? location.pathname === HISTORY
                          ? palette.secondary.main
                          : palette.primary.main
                        : index === 4
                        ? location.pathname === IRREGULARITIES
                          ? palette.secondary.main
                          : palette.primary.main
                        : index === 5
                        ? location.pathname === PRIVATE_TICKET_PURCHASE
                          ? palette.secondary.main
                          : palette.primary.main
                        : index === 6
                        ? location.pathname === SUPPORT
                          ? palette.secondary.main
                          : palette.primary.main
                        : palette.primary.main,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </div>
  );

  const container = props.window ? props.window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', margin: 0, width: '100%' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { md: `calc(100% - ${props.drawerWidth}px)` },
          ml: { md: `${props.drawerWidth}px` },
          boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.3)',
        }}
      >
        <Container
          maxWidth='xl'
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              justifyContent: { md: 'flex-end', xs: 'space-between' },
              alignItems: 'center',
              boxShadow: 0,
            }}
          >
            <IconButton
              color='primary'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <Menu />
            </IconButton>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                onClick={() => {
                  navigate(PROFILE);
                  setMobileOpen(!mobileOpen);
                }}
                sx={{ p: 0 }}
              >
                <Avatar />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        component='nav'
        sx={{
          width: { md: props.drawerWidth },
          flexShrink: { md: 0 },
        }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: props.drawerWidth,
              borderRight: 0,
              backgroundColor: palette.grey[100],
            },
          }}
        >
          {drawer}
          <Typography
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 1,
            }}
          >
            Versão {projectPackage.version}
          </Typography>
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: props.drawerWidth,
              borderRight: 0,
              backgroundColor: palette.grey[100],
            },
          }}
          open
        >
          {drawer}
          <Typography
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 8,
            }}
          >
            Versão {projectPackage.version}
          </Typography>
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          width: {
            xs: `100%`,
            md: `calc(100% - ${props.drawerWidth}px)`,
          },
        }}
      >
        {children}
      </Box>
      <AppBar
        position='fixed'
        sx={{
          width: { md: `calc(100% - ${props.drawerWidth}px)` },
          ml: { md: `${props.drawerWidth}px` },
          boxShadow: 0,
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
          }}
        >
          <AppBar
            position='static'
            sx={{ backgroundColor: 'white', boxShadow: 0 }}
          >
            <Container maxWidth='xl'>
              <Toolbar
                disableGutters
                sx={{
                  justifyContent: { md: 'flex-end', xs: 'space-between' },
                  alignItems: 'center',
                }}
              >
                <IconButton
                  color='primary'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { md: 'none' } }}
                >
                  <Menu />
                </IconButton>

                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      // fontSize: { md: '22px', xs: '16px' },
                      width: '100%',
                      textAlign: 'center',
                      fontSize: {
                        xs: '16px',
                        sm: '18px',
                        md: '18px',
                        lg: '20px',
                        xl: '26px',
                      },
                    }}
                    color='primary'
                  >
                    {getPageTitle()}
                  </Typography>
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                  <IconButton onClick={() => navigate(PROFILE)} sx={{ p: 0 }}>
                    <Avatar />
                  </IconButton>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </div>
      </AppBar>
      {/* <Footer drawerWidth={props.drawerWidth} /> */}
    </Box>
  );
};
