import { authSlice } from './slices/Auth';
import { historySlice } from './slices/History';
import { irregularitiesSlice } from './slices/Irregularities';
import { legalSlice } from './slices/Legal';
import { paymentMethodsSlice } from './slices/PaymentMethods';
import { pixSlice } from './slices/Pix';
import { privateTicketPurchaseSlice } from './slices/PrivateTicketPurchase';
import { profileSlice } from './slices/Profile';
import { supportSlice } from './slices/Support';
import { ticketPurchaseSlice } from './slices/TicketPurchase';
import { vehiclesSlice } from './slices/Vehicles';

export const authReducer = authSlice.reducer;
export const historyReducer = historySlice.reducer;
export const legalReducer = legalSlice.reducer;
export const irregularitiesReducer = irregularitiesSlice.reducer;
export const paymentMethodsReducer = paymentMethodsSlice.reducer;
export const pixReducer = pixSlice.reducer;
export const privateTicketPurchaseReducer = privateTicketPurchaseSlice.reducer;
export const profileReducer = profileSlice.reducer;
export const supportReducer = supportSlice.reducer;
export const ticketPurchaseReducer = ticketPurchaseSlice.reducer;
export const vehiclesReducer = vehiclesSlice.reducer;

export * from './slices/Auth';
export * from './slices/History';
export * from './slices/Legal';
export * from './slices/Irregularities';
export * from './slices/PaymentMethods';
export * from './slices/PrivateTicketPurchase';
export * from './slices/Pix';
export * from './slices/Profile';
export * from './slices/Support';
export * from './slices/TicketPurchase';
export * from './slices/Vehicles';
export * from './types';
export * from './Store';
