import { Grid, Typography } from '@mui/material';
import { replace } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { Button, Modal } from '../../../components';
import { PRIVATE_PIX } from '../../../routes';
import {
  useDispatch,
  useSelector,
  privateTicketPurchaseSlice,
  fetchBuyPrivateTicket,
  fetchBuyPrivateTicketWithPix,
  fetchOpenPrivatePix,
  pixSlice,
  vehiclesSlice,
} from '../../../stores';
import { StyledModalWrapper } from '../styles';
import 'react-credit-cards/es/styles-compiled.css';

export const BuyPrivateTicketModal = (props: {
  handleResetTicket: () => void;
  code: string;
  selectedVehicle: {
    id: number;
    plate: string;
    type: string;
    model: string;
    default: number;
    used: number;
  };
  selectedPaymentMethod: {
    id: number;
    number: string;
    brand: string;
    bankId: number | null;
    default: 0 | 1;
  };
  entryTime: string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { driverId, cpf } = useSelector((state) => state.profileReducer);
  const {
    privateTicketData,
    buyPrivateTicketIsOpen,
    privateTicketPurchaseLoading,
  } = useSelector((state) => state.privateTicketPurchaseReducer);
  const { privatePixLoading } = useSelector((state) => state.pixReducer);

  const handleClose = () => {
    dispatch(privateTicketPurchaseSlice.actions.toggleBuyPrivateTicketIsOpen());
  };

  const handleSubmit = () => {
    if (privateTicketData && props.selectedVehicle) {
      if (props.selectedPaymentMethod.id === 0) {
        const params = {
          ...privateTicketData,
          driverId: driverId.toString(),
          vehicleId: props.selectedVehicle.id.toString(),
          stayTime: privateTicketData?.stayTime,
          price: privateTicketData?.cloudParkPrice,
          paymentMethodId: props.selectedPaymentMethod.id.toString(),
        };
        dispatch(
          fetchBuyPrivateTicketWithPix({
            ...params,
            driverDocument: VMasker.toNumber(cpf).toString(),
          }),
        ).then((res: any) => {
          if (res.meta.requestStatus === 'fulfilled') {
            if (privateTicketData.cloudParkPrice === '0,00') {
              // dispatch(
              //   fetchOpenPrivateTicket({ driverId: driverId.toString() }),
              // );
              dispatch(
                privateTicketPurchaseSlice.actions.toggleBuyPrivateTicketIsOpen(),
              );
              props.handleResetTicket();
              dispatch(
                vehiclesSlice.actions.setUsedVehicle(
                  privateTicketData?.vehicleId
                    ? parseInt(privateTicketData.vehicleId)
                    : 0,
                ),
              );
              toast.success('Ticket pago com sucesso');
            } else {
              dispatch(
                privateTicketPurchaseSlice.actions.toggleBuyPrivateTicketIsOpen(),
              );
              dispatch(pixSlice.actions.setHasOpenPrivatePix(true));
              props.handleResetTicket();
              toast.success('Pix gerado com sucesso.');
              navigate(PRIVATE_PIX);
            }
          }
        });
      } else {
        const params = {
          ...privateTicketData,
          driverId: driverId,
          vehicleId: props.selectedVehicle.id,
          paymentMethodId: props.selectedPaymentMethod.id,
          cloudParkPrice: parseFloat(privateTicketData.cloudParkPrice),
        };
        dispatch(fetchBuyPrivateTicket(params)).then((res: any) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(
              privateTicketPurchaseSlice.actions.toggleBuyPrivateTicketIsOpen(),
            );
            props.handleResetTicket();
            toast.success('Ticket pago com sucesso');
          }
        });
      }
    }
  };

  return (
    <Modal
      open={buyPrivateTicketIsOpen}
      onClose={handleClose}
      title='Confirmação de compra'
    >
      <StyledModalWrapper>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Código:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {props.code}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Veículo:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {`${privateTicketData?.cloudParkPlate} (${props.selectedVehicle?.type})`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Horário de entrada:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {props.entryTime}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Meio de pagamento:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {props.selectedPaymentMethod &&
              props.selectedPaymentMethod.id !== 0
                ? `**** ${props.selectedPaymentMethod.number} (${props.selectedPaymentMethod.brand})`
                : 'PIX'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Valor:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {privateTicketData
                ? privateTicketData.cloudParkPrice === '0,00'
                  ? 'Grátis'
                  : `R$${replace(
                      privateTicketData.cloudParkPrice.toString(),
                      '.',
                      ',',
                    )}`
                : 'R$0,00'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Button
            variant='contained'
            label='Comprar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={privateTicketPurchaseLoading || privatePixLoading}
            style={{ width: '100%' }}
          />
        </Grid>
      </StyledModalWrapper>
    </Modal>
  );
};
