// All user related database operations can be defined here.
import { api } from '..';
import { ReadCloudParkTicketResponse, BuyPrivateTicketResponse } from './types';

export * from './types';

export const fetchReadCloudParkTicketService = async (params: {
  driverId: string;
  cloudParkTicketId: string;
}) => {
  const { driverId, cloudParkTicketId } = params;
  return await api
    .get<ReadCloudParkTicketResponse>(
      `privateparkings/driver/${driverId}/cloudpark/${cloudParkTicketId}`,
    )
    .then((res) => {
      return { ...res.data, driverId: params.driverId };
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchBuyPrivateTicketService = async (params: {
  driverId: number;
  privateParkingId: string;
  vehicleId: number;
  stayTime: string;
  paymentMethodId: number;
  cloudParkTicketId: string;
  cloudParkToken: string;
  cloudParkId: string | null;
  cloudParkContract: string;
  cloudParkEntryDate: string;
  cloudParkPlate: string;
  cloudParkPrice: number;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<BuyPrivateTicketResponse>(
      `privateparkings/driver/${driverId}/buyticket`,
      otherParams,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
